var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('h3',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v("mdi-format-list-checks")]),_vm._v("Tipos de Solicitação ")],1),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":_vm.newSolicitationType}},[_vm._v(" Criar tipo de solicitação ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.solicitationType_list.data,"search":_vm.search,"locale":"pt","loading":_vm.loadingSolicitation},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.editable)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.dialogRulesList(item)}}},on),[_vm._v("mdi-alpha-r-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Regras da Solicitação")])]):_vm._e(),(item.editable)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.dialogGroupAssociate(item)}}},on),[_vm._v("mdi-link-plus")])]}}],null,true)},[_c('span',[_vm._v("Associar Grupo")])]):_vm._e(),(item.editable)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item.id)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(item.deletable)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.solicitationTypeItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])]):_vm._e()]}}])})],1),(_vm.dialog == true)?_c('GroupAssociateComponent',{attrs:{"selectedId":_vm.selectedId},on:{"closeDialog":function($event){_vm.dialog = false}}}):_vm._e(),(_vm.dialogRules == true)?_c('RulesList',{attrs:{"selectedId":_vm.selectedId},on:{"closeDialog":function($event){_vm.dialogRules = false}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }