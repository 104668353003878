<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>
              <v-icon class="mr-3" color="primary"
                >mdi-format-list-checks</v-icon
              >Tipos de Solicitação
            </h3>
            <v-btn class="ml-3" color="primary" @click="newSolicitationType">
              Criar tipo de solicitação
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="columns"
            :items="solicitationType_list.data"
            :search="search"
            locale="pt"
            :loading="loadingSolicitation"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip left v-if="item.editable">
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="dialogRulesList(item)"
                    v-on="on"
                    >mdi-alpha-r-circle-outline</v-icon
                  >
                </template>
                <span>Regras da Solicitação</span>
              </v-tooltip>
              <v-tooltip left v-if="item.editable">
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="dialogGroupAssociate(item)"
                    v-on="on"
                    >mdi-link-plus</v-icon
                  >
                </template>
                <span>Associar Grupo</span>
              </v-tooltip>
              <v-tooltip left v-if="item.editable">
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item.id)"
                    v-on="on"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip right v-if="item.deletable">
                <template v-slot:activator="{ on }">
                  <v-icon small @click="solicitationTypeItem(item)" v-on="on">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <GroupAssociateComponent
          @closeDialog="dialog = false"
          v-if="dialog == true"
          :selectedId="selectedId"
        />
        <RulesList
          @closeDialog="dialogRules = false"
          v-if="dialogRules == true"
          :selectedId="selectedId"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GroupAssociateComponent from "@/components/group/GroupAssociateComponent";
import RulesList from "@/components/rulesComponent/rulesList";

export default {
  components: {
    GroupAssociateComponent,
    RulesList
  },
  data() {
    return {
      search: "",
      loadingSolicitation: true,
      dialog: false,
      dialogRules: false,
      selectedId: "",
      error: "",
      columns: [
        { text: "Tipo Solicitação", value: "display_name" },
        { text: "Fornecedor", value: "fornecedor" },
        { text: "Data de cadastro", value: "created_at_format" },
        { text: "Data de atualização", value: "updated_at_format" },
        { text: "", align: "end", value: "actions", sortable: false }
      ]
    };
  },
  async mounted() {
    await this.loadSolicitationTypeList().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loadingSolicitation = false;
  },
  computed: {
    ...mapState("solicitationType", [
      "solicitation_type",
      "solicitationType_list"
    ])
  },
  methods: {
    ...mapActions("solicitationType", [
      "loadSolicitationTypeList",
      "deleteSolicitationType"
    ]),

    newSolicitationType() {
      this.$router.push({ name: "solicitation-type-new" });
    },

    editItem(id_solicitationType) {
      this.$router.push({
        name: "solicitation-type-edit",
        params: { id: id_solicitationType }
      });
    },

    async solicitationTypeItem(solicitationType) {
      this.loadingSolicitation = true;
      await this.$swal({
        icon: "warning",
        text: `Tem certeza que deseja excluir o tipo de solicitação ${solicitationType.display_name}?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sim, remover o tipo de solicitação!"
      }).then(async result => {
        if (result.value) {
          await this.deleteSolicitationType(solicitationType).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
        }
      });
      this.loadingSolicitation = false;
    },

    dialogGroupAssociate(group) {
      this.dialog = true;
      this.linkGroup = group;
      this.selectedId = group.id;
    },

    dialogRulesList(rule) {
      this.dialogRules = true;
      this.selectedId = rule.id;
    }
  }
};
</script>
